<template>
  <div class="schedule">
    <div class="basic-info">
      <van-field label="VIN" :value="itemData.vinCode" readonly input-align="right"/>
      <van-field :label="$t('车牌')" readonly input-align="right">
        <template #input>
          <span class="car-number">{{ itemData.carNumber }}</span>
        </template>
      </van-field>
    </div>
    <div class="calendar">
      <p class="title"> 
        <span>{{$t('预约时间')}}</span>
      </p>
      <van-calendar :poppable="false" 
        :show-title="false" 
        :first-day-of-week="1"
        :show-confirm="false"
        :min-date="getMinDate"
        :max-date="getMaxDate" 
        :style="{ height: '394px' }"
        :formatter="formatter"
        @select="handleSelect">
        <template v-if="isLoading" slot="bottom-info" slot-scope="scope">
          <span v-if="getDot(scope)" class="dot">{{getDot(scope)}}</span>
        </template>
      </van-calendar>
    </div>
  
    <div v-if="appointList.length" class="appointment-info">
      <p class="title">
        <img :src="timeIcon" class="img">
        {{$t('预约详细信息')}}</p>
      <van-cell-group inset>
        <van-cell v-for="e in appointList" :key="e.id">
          <template #title>
            <span v-if="e.appointmentType">{{ e.followUserName||e.customerName }}</span>
            <span v-else>{{ e.operateUserName }}</span>
          </template>
          <template #extra>
            <div v-if="e.appointmentType" class="cell-content">
              <span>{{ e.appointmentTime }} </span>
              <span>{{ e.typeName || '--' }} </span>
              <span>{{ e.tableType | getdectText(e, 'tableType', $store,'2022') }}</span>
              </div>
            <div v-else class="cell-content">
              <span>{{ e.processTime }} </span> 
              <span>{{ dictMap[e.processResult] }} </span>           
              <span>{{ e.typeName }}</span>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
  </div>
  </template>
<script>
import CarManageServices from '@/services/carManageService'
import { getCalendarList } from '@/services/appointment'
import moment from 'moment'
export default {
  filters:{
    getdectText(val,trip,field,store,dictCode){
      // 不需要查字典
      if (!dictCode) return val
      // 没有值的情况
      if (!trip[field]&&val!==0) return '--'
      const dictHash = store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        const list = dictHash[dictType] || []
        return list.map(({ code, id, name }) => ({
          id,
          label: name,
          value: code,
        }))
      }
      const options = getOptionsByDictType(dictCode)
      const theOne = options.find(item=>item.value == trip[field])
      return theOne?.label || ''
    }
  },
  data(){
    return {
      itemData:{
        id:'',
        vinCode:'',
        carNumber:''
      },
      show:true,
      timeIcon:require('../../images/appoint-time.png'),
      appointList:[],
      isLoading: false,
      calendarCountMap:{}
    }
  },
  computed:{
    // 可选时间范围是过去六个月～未来六个月
    getMinDate(){
      return moment().subtract(1, 'years').toDate()
    },
    getMaxDate(){
      return moment().subtract(-1, 'years').toDate()
    },
    dictMap(){
      const dictList = this.$store.getters.getDictHash('2007')
      const result = {}
      dictList.forEach((item)=>{
        result[item.code] = item.name
      })
      return result
    }
  },
  mounted(){
    const { id, vinCode,carNumber } = this.$route.query
    this.itemData.id = id
    this.itemData.vinCode = vinCode
    this.itemData.carNumber = carNumber
    const now = new Date()
    this.getDay(moment(now).format('YYYY-MM-DD'))
    // 获取日历上的数量
    var firstDayOfMonth = moment().subtract(1, 'years').format('YYYY-MM-DD')
    var lastDayOfMonth = moment().subtract(-1, 'years').format('YYYY-MM-DD')
    this.getCalendarCount(firstDayOfMonth,lastDayOfMonth)
  },
  methods:{
    handleSelect(date){
      this.getDay(moment(date).format('YYYY-MM-DD'))
    },
    // 当某个月份进入可视区域时触发	
    // handleMonthShow({ date,title }){
    //   var firstDayOfMonth = moment(date).startOf('month').format('YYYY-MM-DD')
    //   var lastDayOfMonth = moment(date).endOf('month').format('YYYY-MM-DD')
    //   this.getCalendarCount(firstDayOfMonth,lastDayOfMonth)
    // },
    // 获取某天的预约信息
    async getDay(date){
      const params = {
        appointmentType:'2001001',
        vinCode:this.itemData.vinCode,
        date
      }
      const res = await CarManageServices.carScheduleDayPost(params)
      this.appointList = res
      console.log('res:',res)
    },
    formatter(day) {
      const dateStr = moment(day.date).format('YYYY-MM-DD')
      if (this.calendarCountMap[dateStr]){
        day.bottomInfo = this.calendarCountMap[dateStr]
      }
      return day
    },
    async getCalendarCount(startDate,endDate){
      const params = {
        appointmentType: '2001001',
        vinCode: this.itemData.vinCode,
        startDate,
        endDate
      }
      // const res = await CarManageServices.getCalendarList(params)
      const res = await getCalendarList(params)
      Object.assign(this.calendarCountMap,res)
      this.isLoading = true
    },
    // 预约日期蓝色点
    getDot({ date }) {
      const dateStr = moment(date).format('YYYY-MM-DD')
      return this.calendarCountMap[dateStr] || 0
    },
  }
}
</script>
      <style lang="less" scoped>
      .cell-content{
        color: #969799;
        flex: 2;
        display: flex;
        text-align: right;
        >span:first-child{
          width: 40% !important;
          min-width: 86px;
        }
        >span{
          width: 30%;
        }
      }
      .schedule{
        padding: 16px;
      
        .basic-info{
          background: #FFFFFF;
          box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          padding: 2px;
      
          .car-number{
            height: 20px;
            line-height: 20px;
            padding: 0 4px;
            font-size: 12px;
            background: #3476FE;
            color: #fff;
            border-radius: 2px 2px 2px 2px;
            opacity: 1;
          }
        }
      
        .calendar{
          // height: 394px;
          margin-top: 16px;
          /deep/ .van-calendar__selected-day{
            height: 64px;
          }
          .dot{
            color: @yellow-text;
          }
        }
      
        .appointment-info{
          margin-top: 16px;
          /deep/.van-cell-group--inset{
            margin: 0;
          }
        }
      
        .title{
          display: flex;
          align-items: center;
          height: 48px;
          line-height: 48px;
          font-size: 16px;
          font-family: HYQiHei-60S, HYQiHei;
          font-weight: normal;
          padding-left: 12px;
          color: #0D171A;
          background: #fff;
          .img{
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
        }
      }
      </style>
      